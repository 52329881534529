@font-face {
  font-family: Atkinson-Hyperlegible;
  src: url("fonts/Atkinson-Hyperlegible-Regular-102a.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: Atkinson-Hyperlegible;
  src: url("fonts/Atkinson-Hyperlegible-Italic-102a.woff2") format("woff2");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: Atkinson-Hyperlegible;
  src: url("fonts/Atkinson-Hyperlegible-Bold-102a.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: Atkinson-Hyperlegible;
  src: url("fonts/Atkinson-Hyperlegible-BoldItalic-102a.woff2") format("woff2");
  font-weight: 700;
  font-style: italic;
}


