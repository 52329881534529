body {
  font-size: get-size("500");

  @include media-query("sm") {
    font-size: get-size("600");
  }
}

h1, h2, h3, h4, h5, h6 {
  color: get-color("heading");
}

h1 {
  font-size: get-size("700");

  @include media-query("sm") {
    font-size: get-size("1000");
  }
}

h2 {
  font-size: get-size("600");

  @include media-query("sm") {
    font-size: get-size("900");
  }
}

a, a:visited, a:active {
  color: get-color("link");
  text-shadow: none;
}

a {
  //text-decoration: white wavy underline;
  -webkit-text-decoration-color: get-color("light");
  text-decoration-color: get-color("light");
}

a:hover {
  text-underline-offset: 0.18em;
}

blockquote, dl, figcaption, li, p {
  max-width: 50ch;
}
