.main-nav {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-height: 100px;

  @include media-query("sm") {
    flex-direction: row;
  }

  > * {
    max-height: 100%;
  }

  &__start {
    display: flex;
    justify-content: center;
  }

  &__end {
    @include media-query("sm") {
      margin-left: auto;
      margin-right: 0;
    }
  }

  a.main-nav__item {
    color: get-color("heading");
    i { color: get-color("text") }
    @include apply-utility("text", "600");

    @include media-query("sm") {
      @include apply-utility("text", "700");
    }
  }

  a:not(:hover) {
    text-decoration: none;
  }
}
