.cluster {
  --cluster-justify: flex-start;
  --cluster-align: center;

  display: flex;
  flex-wrap: wrap;
  justify-content: var(--cluster-justify);
  align-items: var(--cluster-align);
  margin: 0 auto;
  grid-gap: .5rem;
  gap: .5rem;
}

.cluster > * {
  margin: .5rem;
}
