.sign-up {
  margin-top: get-size("1200");
  overflow: initial;
  text-align: center;

  border: 1px solid get-color("heading");
  border-radius: 0.2em;
  box-shadow: 5px 5px 0px get-color("heading");
  padding: 1.5em;

  &__cta {
    color: get-color("text");

    font-size: get-size("500");

    @include media-query("sm") {
      font-size: get-size("700");
    }
  }

  @include media-query("md") {
    max-width: 45rem;
  }

  &__email, &__submit {
    font-size: get-size("400");

    @include media-query("sm") {
      font-size: get-size("500");
    }
  }

  &__email {
    padding: 0.65em 1em;
    margin: 0;
    background: get-color("background");
    border: 1px solid get-color("text");
    border-right-color: transparent;
    border-radius: 0.2em 0 0 0.2em;
    display: block;
    width: 100%;
    min-width: 0;
    color: inherit;

    &:focus {
      outline: none;
    }

    &::placeholder {
      color: get-color("text");
    }
  }

  &__submit {
    padding: 0.5em 1em;
    color: white;
    background: get-color("text");
    border: 1px solid get-color("text");
    border-left-color: transparent;
    border-radius: 0 0.2em 0.2em 0;
    cursor: pointer;
    margin: 0;

    &:focus {
      outline: none;
    }
  }
}
