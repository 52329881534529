@import "../../node_modules/modern-css-reset/dist/reset.min.css";

@import "config";
@import "../../node_modules/gorko/gorko.scss";

@import "fonts";
@import "typography";
@import "colours";

@import "utilities/flow";
@import "utilities/flex-column";
@import "utilities/cluster";

@import "blocks/main-nav";
@import "blocks/sign-up";
@import "blocks/posts";

.main-outer-wrapper {
  display: flex;
  align-items: flex-start;
}

.main-inner-wrapper {
  @include apply-utility("width", "full");
  padding: get-size("700");
}

.main-footer {
  text-align: center;
}

main {
  margin-top: 2rem;

  @include media-query("sm") {
    margin-top: 4rem;
  }
}

.logo {
  width: 16rem;
  align-items: flex-start;

  > img {
    position: sticky;
    top: get-size("300");
  }
}

.head-logo {
  width: 8rem;
  max-height: 8rem;
  margin: -1rem;
}

.wrapper {
  width: 90vw;
  width: clamp(16rem, 93vw, 70rem);
  margin-left: auto;
  margin-right: auto;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  position: relative;
}

.highlight {
  margin: auto;
  max-width: 75vw;

  pre {
    padding: 2em;
    white-space: break-spaces;
    max-width: 50ch;
  }
}
